.main-app {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* height: 100vh; */
  background-color: white;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  background-color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  transition: clip-path 0.3s ease-in-out;
  transition-delay: 1s;
}

.loader-wrapper.hidden {
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  color: lightskyblue;
}

.loader:before,
.loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}

.loader:after {
  color: lightgreen;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {

  0%,
  100% {
    box-shadow: .4em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: .4em .4em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 .4em 0 0px currentcolor;
  }

  37% {
    box-shadow: -.4em .4em 0 0 currentcolor;
  }

  50% {
    box-shadow: -.4em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -.4em -.4em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -.4em 0 0 currentcolor;
  }

  87% {
    box-shadow: .4em -.4em 0 0 currentcolor;
  }
}