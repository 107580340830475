@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  font-family: "Bebas Neue";
  font-weight: normal;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}