.project {
    display: grid;
    grid-template-columns: 260px 300px;
    grid-template-rows: 70px 1fr;
    column-gap: 150px;
    row-gap: 20px;
    max-width: 650px;
}

.project__title {
    display: flex;
    gap: 20px;
    align-items: center;
}

.project__name {
    grid-column: 1;
    grid-row: 1;
    font-family: "Bebas Neue";
    font-size: 60px;
    align-self: flex-start;
}

.project__link {
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: block;
    opacity: 0.5;
    /* padding: 2px; */
    margin-bottom: 5px;
    transition: opacity 0.3s ease-in-out;
}

.project__link:hover {
    opacity: 1;
}

.project__details {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 300px;
    z-index: 3;
}

.project__description {
    line-height: 1.5;
    white-space: pre-wrap;
}

.project__shots {
    grid-row: 1/3;
    grid-column: 2;
    width: 200px;
    align-self: center;
}

.project__image {
    width: 100%;
    object-fit: contain;
}

.project__tech-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.left-align {
    transform: translateX(-30px);
}

.right-align {
    transform: translateX(30px);
}

.tech {
    padding: 4px 7px;
    color: white;
    background-color: rgb(17, 117, 205);
    font-size: 16px;
    font-family: 'Bebas Neue';
}

.project__shots-clone {
    display: none;
}

@media screen and (max-width: 760px) {
    .project {
        grid-template-columns: 1fr;
        grid-template-rows: 70px 1fr 120px;
        padding: 0 40px;
    }

    .project__name {
        grid-column: 1;
    }

    .project__details {
        grid-column: 1;
        grid-row: 2;
    }

    .project__shots {
        grid-column: 1;
        grid-row: 3;
        display: flex;
        gap: 10px;
        width: 100%;
        overflow-x: hidden;
    }

    .left-align {
        transform: none;
    }

    .right-align {
        transform: none;
    }

    .project__image {
        width: 220px;
        position: relative;
        animation: autoScroll 10s linear infinite;
    }

    .project__shots-clone {
        display: flex;
        gap: 10px;
    }

    @keyframes autoScroll {
        0% {
            left: 0;
        }
        100% {
            left: calc((-220px * 3) + (-10px * 3))
        }
    }
}