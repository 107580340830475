.about-section {
    min-height: 100vh;
    padding: 0 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.4;
    background-color: whitesmoke;
}

.about-section__centered {
    display: grid;
    grid-template-rows: 1fr 1.1fr;
    grid-template-columns: 1.4fr 1fr;
    column-gap: 40px;
    max-width: 900px;
}

.about-section__title {
    font-size: 80px;
    position: relative;
    color: rgba(20, 20, 20);
    z-index: 1;
    font-family: "Bebas Neue";
}

.about-section__photo {
    grid-column: 1;
    grid-row: 2;
    min-width: 240px;
    height: 240px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid white;
}

.about-section__text {
    grid-row: 1/3;
    display: flex;
    flex-direction: column;
    gap: 14px;
    text-align: justify;
}

.intro-text-1 {
    font-size: 18px;
    background-color: rgb(55, 136, 208);
    color: white;
    padding: 3px 10px;
    width: 160px;
    transform: translateX(-10px);
}

.intro-text-2 {
    background-color: rgb(27, 195, 130);
    color: white;
    padding: 3px 6px;
    transform: translate(-6px, -3px);
    font-size: 12px;
    width: 345px;
}

@media screen and (max-width: 760px) {
    .about-section {
        padding: 0 20px;
    }
    .about-section__centered {
        display: grid;
        grid-template-rows: 80px 1fr;
        grid-template-columns: 1fr 90px;
        column-gap: 20px;
    }

    .about-section__title {
        font-size: 40px;
    }

    .about-section__photo {
        grid-column: 2;
        grid-row: 1;
        min-width: 80px;
        height: 80px;
    }

    .about-section__text {
        line-height: 1.5;
        grid-row: 2;
        grid-column: 1/3;
        display: flex;
        flex-direction: column;
        gap: 14px;
        text-align: justify;
    }
}